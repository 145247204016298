const tmpConsole = {
    //log: console.log
    log: ()=>{}
};

export function totalPrice(modules, discount=null) {
    tmpConsole.log("Product price:");
    discount = discount || 0;
    tmpConsole.log("\tDiscount: "+discount);
    const modulesCost = totalModulesPrice(modules, discount);
    tmpConsole.log("\tWorks:");
    const worksCost = modules.filter(el => el.PRICE_PERCENT).reduce((acc, el) => {
        const price = (el.PRICE_PERCENT / 100) * modulesCost;
        tmpConsole.log("\t" + el.NAME + ": " + price);
        return acc + price;
    }, 0);
    const total = modulesCost + worksCost;
    tmpConsole.log("-----");
    tmpConsole.log("\tTotal: " + total);
    tmpConsole.log("-----");
    return total;

}

export function totalModulesPrice(modules, discount=null) {
    tmpConsole.log("\tModules:");
    discount = discount || 0;
    const modulesCost = modules.filter(el => el.PRICE).reduce((acc, el) => {
        tmpConsole.log("\t" + el.NAME + ": " + el.PRICE);
        return acc + +el.PRICE * (1 - discount);
    }, 0);
    return modulesCost;

}

export const MODULE_TYPES = {
    //MODULE_ARTICLE_G1: 'G1',
    UNDER: 'Закладная',
    BASE: 'Основание',
    STANDARD: 'Модуль',
    INTERNAL: 'Встроенный элемент',
    WORKS: 'Работы',
}