<template>
	<div class="product-table mb-2"><!-- всегда нужен отступ вниз -->

		<v-data-table :headers="headers"
					  :items="modules"
					  :item-class="itemClass"
					  :items-per-page="-1"
					  hide-default-footer
					  @click:row="item=>$emit('click', item)">
			<template v-slot:item.number="{ item, index }">
				{{ index + 1 }}
			</template>

			<template v-slot:item.HEIGHT="{ item }">
				<span v-if="item.HEIGHT>0">{{ item.HEIGHT | nf(1) }} м</span>
				<span v-else>&mdash;</span>
			</template>

			<template v-slot:item.PRICE="{ item }">
				<template v-if="item.PRICE>0">
					<div v-if="order.DISCOUNT" class="caption text-no-wrap grey--text text-decoration-line-through">
						{{ item.PRICE | nf }} ₽
					</div>
					<div>{{ item.PRICE * (1 - order.DISCOUNT) | nf }} ₽</div>
				</template>
				<span v-else-if="item.PRICE_PERCENT>0">{{
						(item.PRICE_PERCENT / 100) * totalModulesPrice | nf
					}} ₽</span>
				<span v-else>&mdash;</span>
			</template>

			<template v-slot:item.actions="{ item, index }" class="d-flex justify-end">
				<v-btn icon title="Удалить" @click.stop="onDelete(item, index)">
					<v-icon>mdi-delete-outline</v-icon>
				</v-btn>
			</template>

			<template v-if="modules.length" v-slot:body.append>
				<tr class="append">
					<td colspan="2"></td>
					<td class="d-flex justify-end align-center">
						<!--            <div class="mr-4"><span>Высота:</span></div>-->
					</td>
					<td>
						<div class="d-flex justify-center align-center">
							<!--              <div class="mr-4"><span>Высота:</span></div>-->
							<b>{{ heightTotal | nf(1) }} м</b>
						</div>
					</td>
					<td>
						<div class="d-flex justify-end align-center">
							<div class="mr-4"><span>Итого:</span></div>
							<div>
								<div v-if="order.DISCOUNT"
									 class="caption text-no-wrap grey--text text-decoration-line-through">
									{{ totalPriceWithoutDiscount | nf }} ₽
								</div>
								<b>{{ totalPrice | nf }} ₽</b>
							</div>
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>
<script>
// import {nf} from "@/utils/string";
import TextField from "@/components/ui/text-field"
import Quantity from "@/components/ui/quantity.vue";
import {totalPrice, totalModulesPrice, MODULE_TYPES} from "@/utils/product";

export default {
	name: 'product-table',
	components: {Quantity, TextField},
	props: {
		modules: {
			type: Array,
		},
		order: {
			type: Object,
		},
		selectedModule: {
			type: Object,
		},
		hoveredModule: {
			type: Object,
		}
	},
	data() {
		return {
			headers: [
				{text: '#', value: 'number', sortable: false},
				{text: 'Артикул', value: 'ARTICLE', sortable: false},
				{text: 'Модуль', value: 'NAME', sortable: false, width: "40%"},
				{text: 'Высота', value: 'HEIGHT', sortable: false, align: 'center'},
				// {text: 'Кол-во', value: 'amount', sortable: false, align: 'center'},
				{text: 'Цена', value: 'PRICE', sortable: false, align: 'right'},
				{text: '', value: 'actions', align: 'right', sortable: false},
			],
			MODULE_TYPES
		}
	},
	computed: {
		totalPrice() {
			return totalPrice(this.modules, this.order.DISCOUNT);
		},
		totalPriceWithoutDiscount() {
			return totalPrice(this.modules);
		},
		totalModulesPrice() {
			return totalModulesPrice(this.modules, this.order.DISCOUNT);
		},
		heightTotal() {
			return this.modules.map(el => +el.HEIGHT).reduce((acc, val) => acc + val);
		}
	},
	methods: {
		onDelete(item, index) {
			this.$emit("delete", item, index);
		},
		itemClass(item) {
			const c = [];
			if ( item === this.selectedModule ) c.push("--selected");
			if ( item === this.hoveredModule ) c.push("--hovered");
			return c;
		}
	}
}
</script>

<style lang="scss">
.product-table {
	&__total {
		display: grid;
		grid-template-columns: 3fr 1fr 1fr;
	}

	tbody {
		tr {
			@include transition();

			&.--hovered {
				background-color: rgba(0, 0, 0, 0.03);
			}
			&.--selected {
				background-color: rgba(255, 255, 0, 0.1) !important;
			}
		}

		tr:not(.append) {
			cursor: pointer;
		}
	}
}
</style>
