<template>
	<div class="product">

		<div class="d-flex justify-space-between align-center mb-4">
			<v-btn text @click="$router.push({name: 'order', params: { id: orderId }})">
				<v-icon>mdi-arrow-left-thin</v-icon>
				<span>Коммерческое предложение №{{ $route.params.orderId }}</span>
			</v-btn>
			<div>
				<v-btn class="operation-btn" icon
					   title="Удалить позицию"
					   @click="onDeleteProduct">
					<v-icon>mdi-delete-outline</v-icon>
				</v-btn>
				<v-btn class="operation-btn" :color="isSaved?'success':'primary'"
					   :loading="loadingSave" title="Сохранить предложение"
					   @click="onSave">
					<transition name="fade" mode="out-in">
						<span v-if="isSaved" key="s1">
							<v-icon>mdi-check</v-icon>
						</span>
						<span v-else key="s2" class="d-inline-flex align-center">
							<v-icon>mdi-content-save-outline</v-icon>
							<span class="operation-btn__label">Сохранить</span>
						</span>
					</transition>
				</v-btn>
			</div>
		</div>

		<v-row>
			<v-col cols="12" lg="7" xl="8">
				<h1 class="mb-4">Формирование позиции</h1>

				<product-table v-if="currentModulesSortedForTable?.length"
							   :modules="currentModulesSortedForTable"
							   :order="order"
							   :selected-module="selectedModule"
							   :hovered-module="hoveredModule"
							   @click="onClickModule"
							   @over="onOverModule"
							   @delete="onDeleteModule"/>
				<v-btn color="primary" @click="onAddModule">
					<v-icon class="mr-2">mdi-plus</v-icon>
					Добавить модуль/работы
				</v-btn>
			</v-col>
			<v-col cols="12" lg="5" xl="4">
				<product-view :item="product"
							  :modules="modules"
							  :current-modules="currentModules"
							  :selected-module="selectedModule"
							  :hovered-module="hoveredModule"
							  @add="onAddModule"
							  @add-under="onAddUnderModule"
							  @sort="onSortModules"
							  @click="onClickModule"
							  @over="onOverModule"
							  @delete="onDeleteModule"/>
			</v-col>
		</v-row>

		<v-dialog v-model="withModuleDialog" scrollable>
			<v-card class="product__module-dialog">

				<v-card-title class="d-flex justify-space-between">
					Выбор модуля
					<v-btn @click="onCloseModuleDialog" icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text class="product-view__module-dialog-content">

					<v-data-table v-model="currentModules"
								  :headers="dialogHeaders"
								  :items="dialogModules"
								  :items-per-page="-1"
								  hide-default-footer
								  disable-sort
								  height="100%"
								  @click:row="onDialogChooseModule">
						<template v-slot:item.DETAIL_PICTURE="{ item }">
							<div class="product__module-dialog-img">
								<span v-if="item.TYPE===MODULE_TYPES.WORKS" class="caption">работы</span>
								<img v-else-if="item.DETAIL_PICTURE" :src="item.DETAIL_PICTURE" :alt="item.NAME"/>
								<span v-else class="grey--text text--lighten-2 caption">нет изображения</span>
							</div>
						</template>

						<template v-slot:item.HEIGHT="{ item }">
							<span v-if="item.HEIGHT>0">{{ item.HEIGHT | nf(1) }}</span>
							<span v-else>&mdash;</span>
						</template>

						<template v-slot:item.PRICE="{ item }">
							{{ item.PRICE | nf }} ₽
						</template>

						<template v-slot:item.AMOUNT="{ item }">
							<div v-if="item.TYPE === MODULE_TYPES.UNDER" class="text-center">1</div>
							<div v-else class="product-view__module-dialog-input">
								<quantity v-model="item.AMOUNT"/>
							</div>
						</template>

					</v-data-table>

				</v-card-text>

				<!--        <v-card-actions>
						  <v-btn @click="cancelEditing">Отмена</v-btn>
						  <v-spacer/>
						  <v-btn @click="addModules" color="primary">Подтвердить</v-btn>
						</v-card-actions>-->

			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import ProductTable from "@/components/product/product-table";
import ProductView from "@/components/product/product-view";
import TextField from "@/components/ui/text-field";

import draggable from 'vuedraggable';
import {MODULE_TYPES} from "@/utils/product";

import Logout from "@/components/logout.vue";

export default {
	name: "product",
	props: {
		id: String,
		orderId: String,
	},
	components: {TextField, ProductTable, ProductView, draggable},
	data() {
		return {
			item: {
				ID: null,
				NAME: 'Опора 1',
				amount: '1',
				MODULE_IDS: [],
			},

			currentModules: [],
			selectedModule: null,
			hoveredModule: null,

			dialogModules: [],
			withModuleDialog: false,

			totalPrice: 0,
			dialogHeaders: [
				{text: 'Изображение', value: 'DETAIL_PICTURE', width: "128px"},
				{text: 'Модуль/работы', value: 'NAME'},
				{text: 'Артикул', value: 'ARTICLE'},
				{text: 'Высота, м', value: 'HEIGHT', align: 'center'},
				{text: 'Цена, руб., без НДС', value: 'PRICE', align: 'right'},
			],

			loadingSave: false,
			isSaved: false,

			MODULE_TYPES
		}
	},
	computed: {
		modules() {
			return this.$store.state.page?.ProductItemController?.modules; // modules important name for bitrix
		},
		product() {
			return this.$store.state.page?.ProductItemController?.item;
		},
		order() {
			return this.$store.state.page?.ProductItemController?.order;
		},
		currentModulesSortedForTable() {
			return this.currentModules.sort((a, b) => {

				if (a.TYPE !== MODULE_TYPES.WORKS && b.TYPE === MODULE_TYPES.WORKS) return -1;
				if (a.TYPE === MODULE_TYPES.WORKS && b.TYPE !== MODULE_TYPES.WORKS) return 1;

				if (a.TYPE !== MODULE_TYPES.UNDER && b.TYPE === MODULE_TYPES.UNDER) return -1;
				if (a.TYPE === MODULE_TYPES.UNDER && b.TYPE !== MODULE_TYPES.UNDER) return 1;

				if (a.TYPE !== MODULE_TYPES.BASE && b.TYPE === MODULE_TYPES.BASE) return -1;
				if (a.TYPE === MODULE_TYPES.BASE && b.TYPE !== MODULE_TYPES.BASE) return 1;

				if (a.TYPE !== MODULE_TYPES.INTERNAL && b.TYPE === MODULE_TYPES.INTERNAL) return -1;
				if (a.TYPE === MODULE_TYPES.INTERNAL && b.TYPE !== MODULE_TYPES.INTERNAL) return 1;

				return 0;
			});
		},
		allBaseModules() {
			return this.modules.filter(module => module.TYPE === MODULE_TYPES.BASE);
		},
		allOvergroundModulesAndWorks() {
			return this.modules.filter(
				module => module.TYPE === MODULE_TYPES.STANDARD
					|| module.TYPE === MODULE_TYPES.INTERNAL
					|| module.TYPE === MODULE_TYPES.WORKS
			);
		},
		underModule() {
			return this.modules.find(module => module.TYPE === MODULE_TYPES.UNDER);
		}
	},
	watch: {
		'$route.path': {
			immediate: true,
			handler() {
				this.initItem();
			}
		}
	},
	methods: {
		initItem() {
			if (this.product) {
				this.item = this.product;

				if (this.item.MODULE_IDS?.length) {
					//console.log("MODULES", this.item.MODULE_IDS);
					this.currentModules = this.item.MODULE_IDS.map(product => this.modules.find(module => product === module.ID)).filter(el => !!el).map(el => ({...el}));
					//this.currentModules.forEach(el => el.selected = true);

					// сортируем так, что закладная и основание всегда внизу
					this.currentModules.sort((a, b) => {
						if (a.TYPE === MODULE_TYPES.UNDER && b.TYPE !== MODULE_TYPES.UNDER) return -1;
						if (a.TYPE !== MODULE_TYPES.UNDER && b.TYPE === MODULE_TYPES.UNDER) return 1;

						if (a.TYPE === MODULE_TYPES.BASE && b.TYPE !== MODULE_TYPES.BASE) return -1;
						if (a.TYPE !== MODULE_TYPES.BASE && b.TYPE === MODULE_TYPES.BASE) return 1;

						return 0;
					});

				} else this.currentModules = [];
			} else {
				// для новых опор - всегда добавляем основание
				this.currentModules.push(this.modules.find(module => module.TYPE === MODULE_TYPES.UNDER));
			}
		},
		onDialogChooseModule(newItem) {

			if ( this.selectedModule ) {
				// change selected module
				const index = this.currentModules.findIndex(el=>el===this.selectedModule);
				this.currentModules.splice(index, 1, newItem);
			}
			else {
				// new module
				this.currentModules.unshift({...newItem});
			}

			this.withModuleDialog = false;
		},
		onAddModule() {
			this.selectedModule = null;

			if (!this.currentModules.find(el => el.TYPE === MODULE_TYPES.UNDER)) this.dialogModules = this.modules;
			else if (this.currentModules.find(el => el.TYPE === MODULE_TYPES.BASE)) this.dialogModules = this.allOvergroundModulesAndWorks;
			else this.dialogModules = this.allBaseModules;

			this.withModuleDialog = true;
		},
		onAddUnderModule() {
			this.selectedModule = null;

			this.currentModules.unshift({...this.underModule});
		},
		onSortModules(orderedItems) {
			this.currentModules.sort((a, b) =>{

				const indexA = orderedItems.findIndex(el=>el===a);
				const indexB = orderedItems.findIndex(el=>el===b);

				if ( indexA < indexB ) return -1;
				if ( indexA > indexB ) return 1;

				return 0;
			});
		},
		onClickModule(item, index) {
			this.selectedModule = item;
			//this.hoveredModule = item;

			if (item.TYPE === MODULE_TYPES.BASE) this.dialogModules = this.allBaseModules;
			else this.dialogModules = this.allOvergroundModulesAndWorks;

			this.withModuleDialog = true;
		},
		onOverModule(item, index) {
			//this.hoveredModule = item;
		},
		onDeleteModule(item) {
			this.selectedModule = null;
			this.hoveredModule = null;

			const index = this.currentModules.findIndex(el=>el===item);
			this.currentModules.splice(index, 1);
		},
		onCloseModuleDialog() {
			this.withModuleDialog = false;
		},
		onDeleteProduct() {
			this.$store.state.dialogDelete.visible = true;
			this.$store.state.dialogDelete.callback = () => {
				this.$store.state.dialogDelete.loading = true;

				this.$store.dispatch('del', {
					action: 'ProductItemController',
					params: {
						id: this.product.ID,
						orderId: this.order.ID
					}
				})
					.then(() => {
						this.$router.push({name: 'order', params: {id: this.order.ID}});
					})
					.catch((error) => {
						console.log("ERROR: ", error);
					}).finally(() => {
					this.$store.state.dialogDelete.loading = false;
					this.$store.state.dialogDelete.visible = false;
				})
			}
		},
		onSave() {
			const currentModuleIds = this.currentModules.map(el => el.ID);
			if (currentModuleIds.length) this.item.MODULE_IDS = JSON.stringify(currentModuleIds);
			this.loadingSave = true;
			// this.item.MODULE_COUNTS = JSON.stringify(currentModuleIds.map((_, index) => this.currentModules[index].AMOUNT.toString()));
			// this.item.MODULE_IDS = JSON.stringify(currentModuleIds.map((_, index) => this.currentModules));

			this.order.PRODUCT_COUNTS = JSON.stringify(this.order.PRODUCT_COUNTS);

			this.$store.dispatch(this.item.ID ? 'put' : 'post', {
				action: 'ProductItemController',
				params: {
					item: this.item,
					orderId: this.order.ID
				}
			})/*.then((res) => {
        if (this.$route.name !== 'product' && res.page.ProductItemController?.item?.ID) {
          this.$router.push({
            name: 'product',
            params: {orderId: this.orderId, id: res.page.ProductItemController?.item?.ID}
          });
        }
      })*/.then(() => {
				//this.$router.push({name: 'order', params: {id: this.orderId}})
				this.isSaved = true;
				setTimeout(_ => this.isSaved = false, 2000);
			}).catch((error) => {
				console.log("ERROR: ", error);
			}).finally(() => {
				this.loadingSave = false;
				this.dialog = false;
			})
		},
		/* onFocus(event) {
		   event.target.select();
		 },
		 onInput(item, section) {
		   this.validateDebounced(item, section);
		 },
		 onChange(item, section) {
		   this.validate(item, section);
		 },
		 validate(item, section) {
		   item.amount = parseInt(item.amount);
		   if (isNaN(item.amount)) item.amount = 0;
		   else if (item.amount < 0) item.amount = 0;
		   //else if (typeof item.actual === "number" && item.amount > item.actual) item.amount = item.actual;

		   if (typeof item.rest === "number") {
			 ///item.amount = item.rest;
			 if (item.amount > item.rest) item.amount = item.rest;
			 Vue.set(item, "actual", item.rest - item.amount);
		   }

		   this.$emit("change", item, section);
		 },
		 validateDebounced: debounce(function (item, section) {
		   this.validate(item, section);
		 }, Config.DEBOUNCE_INPUT_TIMEOUT),

		 onEmitInput(item) {
		   console.log('module', item)
		 }*/
	}
}

</script>

<style lang="scss">

.product {

	&__module-dialog {
		background: white;

		&-content {
			margin-bottom: 24px;

			.v-input {
				transition: .2s ease;
				background: $gray;
				margin-top: 8px;
				padding: 4px 12px;

				&:hover {
					background: rgba(211, 211, 211, .5);
				}

				&:first-child {
					margin-top: 0;
				}
			}

			.dialog__swiper {
				position: sticky;
				top: 0;
				width: 100%;
				//background: $gray;
			}
		}

		&-img {
			padding-block: 8px;
			width: 128px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			//height: 64px;
			//object-fit: contain;
			//max-width: 64px;
			img {
				display: block;
				width: 100%;
			}
		}

		.v-input {
			margin-top: 0;
			padding: 0;
		}

		.product__input {
			width: 170px;

			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;

			input {
				text-align: right;
				padding: 2px 0;
			}
		}

		tr {
			cursor: pointer;

			&.v-data-table__selected {
				background: $white !important;
			}

			&:hover {
				background: $color-light-blue !important;
			}
		}
	}
}


</style>
