<template>
	<div class="quantity" @click.stop.prevent="">
		<v-btn :disabled="form.amount<=1" @click.stop.prevent="onAdd(-1)" small text>-</v-btn>
		<v-text-field v-model="form.amount"
					  dense hide-details outlined plain
					  filled background-color="#fff"
					  maxlength="3"
					  @keydown="filterAmountKey"
					  @keyup="fixAmount"/>
		<v-btn :disabled="form.amount>=999" @click.stop.prevent="onAdd(1)" small text>+</v-btn>
	</div>
</template>

<script>
export default {
	name: "quantity",
	props: {
		value: Number
	},
	data() {
		return {
			form: {
				amount: 1,
			},
			isFormValid: true
		}
	},
	methods: {
		onAdd(dir) {
			if (dir < 0 && this.form.amount <= 1) return;
			this.form.amount = Number(this.form.amount);
			this.form.amount += dir;
			this.validateForm();

			this.$emit("input", this.form.amount);
		},
		validateForm() {
			this.isFormValid = this.form.amount > 0;
		},
		filterAmountKey(e) {
			//console.log(e);
			const a = e.key;

			// ignore arrows, del, backspace
			if (
				e.keyCode === 37 ||
				e.keyCode === 39 ||
				e.keyCode === 8 ||
				e.keyCode === 46 ||
				e.keyCode === 35 ||
				e.keyCode === 36 ||
				e.keyCode === 9 ||
				(e.key === "x" && e.ctrlKey) ||
				(e.key === "c" && e.ctrlKey) ||
				(e.key === "v" && e.ctrlKey)
			)
				return true;
		},
		fixAmount(e) {
			if (
				e &&
				(e.keyCode === 37 ||
					e.keyCode === 39 ||
					e.keyCode === 8 ||
					e.keyCode === 46 ||
					e.keyCode === 35 ||
					e.keyCode === 36)
			)
				return true;

			let s = this.form.amount + "";
			s = s.replace(/\D+/g, "");
			//console.log("s=" + s);
			//v = v.substr(1);
			s = Number(s);
			if (isNaN(s)) s = 0;
			else if (s < 0) s = 0;

			this.isFormValid = s > 0;
			this.form.amount = s;

			this.$emit("input", this.form.amount);
		},
	},
	mounted() {
		if ( isNaN(this.value) ) this.form.amount = 1;
		else this.form.amount = this.value;
	}

}
</script>

<style lang="scss">
.quantity {
	width: 120px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	.v-btn {
		min-width: 32px !important;
	}

	.v-text-field {

		input {
			width: 28px;
			text-align: center;
			padding: 2px 0;
			font-size: 13px;
		}

		fieldset {
			height: 40px;
			top: 0;
		}

		legend {
			display: none;
		}
	}
}
</style>
