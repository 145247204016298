<template>
	<div class="product-view-module" :class="itemClass" @click="onClick" @mouseover="onOver">
		<slot name="default">
			<template v-if="item">
				<v-img :src="item.DETAIL_PICTURE" :alt="item.NAME"/>
				<span class="product-view-module__name">
					<span class="product-view-module__name-label">{{ item.NAME }}</span>
					<span class="product-view-module__name-code"> {{
							isNarrow ? item.ARTICLE : ""
						}}</span> <i></i></span>
				<span class="product-view-module__code">{{ item.ARTICLE }}</span>

				<template v-if="item.TYPE !== MODULE_TYPES.UNDER">
					<div class="product-view-module__heights">
						<div class="product-view-module__heights-current">{{ currentHeight | nf(1) }} м</div>
						<div class="product-view-module__heights-own">+{{ item.HEIGHT | nf(1) }} м</div>
					</div>
				</template>

				<v-icon v-if="!readonly" title="Удалить" class="product-view-module__del" small
						@click.stop="$emit('delete', item)">mdi-close
				</v-icon>


			</template>
		</slot>

		<span class="product-view-module__corner"></span>
		<span class="product-view-module__corner --2"></span>
		<span class="product-view-module__corner --3"></span>
		<span class="product-view-module__corner --4"></span>
	</div>

</template>

<script>
import {MODULE_TYPES} from "@/utils/product";

export default {
	name: "product-view-module",
	props: {
		item: {
			type: Object
		},
		visibleModules: {
			type: Array
		},
		disabled: {
			type: Boolean
		},
		selected: {
			type: Boolean
		},
		hovered: {
			type: Boolean
		},
		readonly: {
			type: Boolean
		}
	},
	data() {
		return {
			MODULE_TYPES
		}
	},
	computed: {
		itemClass() {
			const c = [];
			if ( this.isNarrow ) c.push('--narrow');
			if ( this.disabled || this.readonly) c.push('--disabled');
			if ( this.selected ) c.push('--selected');
			if ( this.hovered ) c.push('--hovered');
			if ( this.item?.TYPE===MODULE_TYPES.BASE ) c.push('--base');
			return c;
		},
		isNarrow() {
			return this.item?.DETAIL_PICTURE_HEIGHT < 40;	// todo move to const
		},
		currentHeight() {
			if (!this.visibleModules?.length) return 0;
			let height = 0;
			let hasFound = false;
			const totalHeight = this.visibleModules.reduce((total, item, index) => {
				if (item === this.item) hasFound = true;
				if (!hasFound) height += +item.HEIGHT;
				return total + +item.HEIGHT;
			}, 0);

			height = totalHeight - height;

			return height;
		},
	},
	watch: {},
	methods: {
		onClick() {
			if ( this.readonly) return;
			if ( this.disabled) return;
			this.$emit('click', this.item);
		},
		onOver() {
			if ( this.readonly) return;
			if ( this.disabled) return;
			this.$emit('over', this.item);
		}
	}
}

</script>

<style lang="scss">

$product-view-module-width: 160px;
$product-view-module-label-top: 16px;
//$gradient-padding-top: 80px;

.product-view-module {
	position: relative;
	z-index: 1;
	width: $product-view-module-width;
	//height: $product-view-module-width; // same as width
	display: grid;
	place-items: center;
	cursor: pointer;
	@include transition();

	&:after {
		@include transition();
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 1px dashed transparent;
	}

	&:hover, &.--hovered, &.--selected {
		background-color: rgba(0, 0, 0, 0.03);

		&:after {
			border-color: $black;
		}

		/*.product-view-module__corner {
			opacity: 0;
		}*/

		.product-view-module__name-label {
			opacity: 1;
		}

		.product-view-module__heights-own {
			opacity: 1;
		}

		.product-view-module__del {
			opacity: .3;
		}
	}

	&.--selected {
		background-color: rgba(255, 255, 0, 0.1) !important;
	}

	&.--disabled {
		cursor: default;
		/*.product-view-module__corner {
			display: none;
		}
		&:after {
			display: none;
		}*/
	}

	&.--narrow {

		.product-view-module__name {
			top: 50%;
			transform: translateX(-100%) translateY(-50%);
		}

		.product-view-module__code {
			display: none;
		}

		.product-view-module__del {
			top: 50%;
			transform: translateY(-50%);
		}

		.product-view-module__heights {
			top: 50%;
			transform: translateX(100%) translateY(-50%);

			&-own {
				display: none;
			}
		}
	}

	&__corner {
		@include transition();
		display: block;
		width: 12px;
		height: 1px;

		position: absolute;
		top: 0;
		left: 1px;

		transform-origin: top left;
		background-color: $black;
		opacity: 0.3;

		&:after {
			content: '';
			display: block;
			width: 12px;
			height: 1px;
			transform: rotate(90deg);
			transform-origin: top left;

			background-color: $black;
		}

		&.--2 {
			right: -12px;
			top: 1px;
			left: unset;
			transform: rotate(90deg);
		}

		&.--3 {
			bottom: -1px;
			left: 0;
			top: unset;
			transform: rotate(-90deg);
		}

		&.--4 {
			right: -11px;
			bottom: -2px;
			top: unset;
			left: unset;
			transform: rotate(180deg);
		}
	}

	&__name {
		position: absolute;
		left: -16px;
		top: $product-view-module-label-top;
		transform: translateX(-100%);
		text-align: right;
		//white-space: nowrap;
		width: $product-view-module-width;

		&-label {
			opacity: 0.3;
			@include transition();
		}
	}

	&__code {
		position: absolute;
		left: 16px;
		top: $product-view-module-label-top;
		white-space: nowrap;
	}

	&__heights {
		position: absolute;
		width: 32px;
		right: -16px;
		top: 2px; //$product-view-module-label-top;
		transform: translateX(100%);
		text-align: right;
		white-space: nowrap;

		&-own {
			opacity: 0.3;
			@include transition();
		}
	}

	&__del {
		position: absolute !important;
		right: 16px;
		top: $product-view-module-label-top;
		z-index: 2;
		opacity: 0;
		@include transition();

		&:hover {
			opacity: 1 !important;
		}
	}
}

</style>
