<template>
	<div class="product-view size-5">

		<product-view-module v-if="!readonly" class="product-view__module-add" @click="onAddModule">
			<span class="product-view__module-add-btn">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="#aaa" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M5.16667 5.1665V0.166504H6.83333V5.1665H11.8333V6.83317H6.83333V11.8332H5.16667V6.83317H0.166668V5.1665H5.16667Z"/>
				</svg>
			</span>
			<span class="product-view__module-add-label">
					{{
					visibleModules?.length >= 1 ? 'Добавить модуль' : 'Добавить основание'
				}}
				</span>
		</product-view-module>

		<draggable v-model="visibleModules"
				   @start="onModuleDragging"
				   @end="onModuleDragged"
				   :draggable="readonly?null:'.product-view-module:not(.--base)'">
			<product-view-module v-for="(item, index) in visibleModules"
								 :key="item.ID + '.' + index"
								 :item="item"
								 :visible-modules="visibleModules"
								 :selected="selectedModule === item"
								 :hovered="hoveredModule === item"
								 :readonly="readonly"
								 @delete="onDeleteModule"
								 @over="onOverModule"
								 @click="onClickModule(item, index)"/>
		</draggable>

		<div class="product-view__ground">
			<product-view-module v-if="undergroundModule" :item="undergroundModule" :readonly="readonly" disabled @delete="onDeleteModule"/>
			<product-view-module v-else-if="!readonly" class="product-view__module-add" @click="onAddUnderModule">
				<span class="product-view__module-add-btn">
					<svg width="12" height="12" viewBox="0 0 12 12" fill="#aaa" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.16667 5.1665V0.166504H6.83333V5.1665H11.8333V6.83317H6.83333V11.8332H5.16667V6.83317H0.166668V5.1665H5.16667Z"/>
					</svg>
				</span>
				<span class="product-view__module-add-label">Добавить закладную</span>
			</product-view-module>
		</div>

		<div class="product-view__cloud"/>

	</div>
</template>

<script>
import ProductViewModule from '@/components/product/product-view-module';
import draggable from 'vuedraggable';
import Quantity from "@/components/ui/quantity";
import {MODULE_TYPES} from "@/utils/product";

export default {
	name: "product-view",
	props: {
		product: {
			type: Object
		},
		modules: {
			type: Array
		},
		currentModules: {
			type: Array
		},
		selectedModule: {
			type: Object,
		},
		hoveredModule: {
			type: Object,
		},
		readonly: {
			type: Boolean
		}
	},
	components: {draggable, Quantity, ProductViewModule},
	data() {
		return {
			visibleModules: [],
			isDragging: false,
			MODULE_TYPES,
		}
	},
	computed: {
		undergroundModule() {
			return this.currentModules.find(module => module.TYPE === MODULE_TYPES.UNDER);
		},
	},
	watch: {
		currentModules: {
			immediate: true,
			handler() {
				this.visibleModules = this.currentModules.filter(item => item.DETAIL_PICTURE
					&& item.TYPE !== MODULE_TYPES.WORKS
					&& item.TYPE !== MODULE_TYPES.UNDER
				);
			}
		}
	},
	methods: {
		onModuleDragging() {
			this.isDragging = true;
		},
		onModuleDragged() {
			this.isDragging = false;
			this.$emit('sort', this.visibleModules);
		},
		onAddModule() {
			this.$emit('add');
		},
		onAddUnderModule() {
			this.$emit('add-under');
		},
		onClickModule(item) {
			const index = this.currentModules.findIndex(el => el === item);
			this.$emit('click', item, index);
		},
		onOverModule(item) {
			const index = this.currentModules.findIndex(el => el === item);
			this.$emit('over', item, index);
		},
		onDeleteModule(item) {
			const index = this.currentModules.findIndex(el => el === item);
			this.$emit('delete', item, index);
		},

	}
}

</script>

<style lang="scss">

$product-view-module-width: 160px;
//$gradient-padding-top: 80px;

.product-view {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-top: 24px;


	&__cloud {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		background: radial-gradient(50% 50% at 50% 50%, rgba(164, 204, 255, 0.3) 0%, rgba(164, 204, 255, 0) 100%);
		pointer-events: none;
	}

	&__module-add {
		height: $product-view-module-width / 2;

		&:hover {
			.product-view__module-add-btn {
				svg {
					fill: $black;
				}
			}

			.product-view__module-add-label {
				opacity: .3;
			}
		}

		&-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			width: 32px;
			height: 32px;

			background: $white;
			//opacity: 0.8;
			box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.08);
			border-radius: 50%;

			svg {
				@include transition();
			}
		}

		&-label {
			@include transition();
			position: absolute;
			white-space: nowrap;
			top: 50%;
			left: -24px;
			transform: translateX(-100%) translateY(-50%);
			z-index: 2;
			opacity: 0;
		}
	}

	&__ground {
		position: relative;
		display: flex;
		justify-content: center;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%, rgba(0, 0, 0, 0) 100%);
		padding-bottom: 48px;
		min-height: $product-view-module-width;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background: rgba(0, 0, 0, 0.4);
		}
	}

	&-module {
		margin-left: $product-view-module-width;
	}
}

</style>
