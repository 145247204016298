<template>
  <div class="text-field">

    <v-btn @click.stop="decreaseQuantity" small>-</v-btn>

    <v-text-field v-model="amount"
                  @click.stop
                  maxlength="2"
                  outlined dense hide-details filled background-color="#fff"
    ></v-text-field>

    <v-btn @click.stop="increaseQuantity" small>+</v-btn>

  </div>
</template>

<script>
import {debounce} from "lodash";
import Config from "@/config";

export default {
  name: 'text-field',
  data() {
    return {
      amount: 1,
    }
  },
  methods: {
    increaseQuantity() {
      this.amount++
    },
    decreaseQuantity() {
      if (this.amount > 1) {
        this.amount -= 1;
      }
    },

    onFocus(event) {
      event.target.select();
    },
    onInput(item, section) {
      this.validateDebounced(item, section);
    },
    onChange(item, section) {
      this.validate(item, section);
    },
    validate(item, section) {
      item.amount = parseInt(item.amount);
      if (isNaN(item.amount)) item.amount = 0;
      else if (item.amount < 0) item.amount = 0;
      //else if (typeof item.actual === "number" && item.amount > item.actual) item.amount = item.actual;

      if (typeof item.rest === "number") {
        ///item.amount = item.rest;
        if (item.amount > item.rest) item.amount = item.rest;
        Vue.set(item, "actual", item.rest - item.amount);
      }

      this.$emit("change", item, section);
    },
    validateDebounced: debounce(function (item, section) {
      this.validate(item, section);
    }, Config.DEBOUNCE_INPUT_TIMEOUT),
  }
}
</script>

<style lang="scss">
.text-field {
  width: 170px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  input {
    text-align: right;
    padding: 2px 0;
  }
}
</style>
